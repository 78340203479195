<template>
  <div class="">
    <div class="container w-full mx-auto py-10 px-4 md:px-0">
      <div v-if="!site" class="bg-gray-800 p-10 text-white rounded">
        <a :href="`https://${$route.params.domain}`" rel="nofollow" target="_blank" class="flex items-center space-x-2 mb-4">
          <h1 class="font-bold text-2xl">
            {{ $route.params.domain }}
          </h1>
          <div class="hidden md:inline-block">
            <external-link-icon />
          </div>
        </a>
      </div>

      <div v-if="site">
        <SiteInfo :site="site" />
        <LoginNeedModal v-if="showLoginNeedModal" @close="hideOrShowLoginNeedModal" />
        <SiteFeedbacks :site="site" />

        <Chart />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import LoginNeedModal from "@/components/LoginNeedModal.vue";
import SiteInfo from "@/components/site/SiteInfo.vue";
import SiteFeedbacks from "@/components/site/SiteFeedbacks.vue";
import Chart from "@/components/site/Chart.vue";
import { ExternalLinkIcon } from 'vue-feather-icons'

export default {
  components: {
    LoginNeedModal,
    SiteInfo,
    SiteFeedbacks,
    Chart,
    ExternalLinkIcon
  },
  computed: mapState({
    site: (state) => state.site.site,
    showLoginNeedModal: (state) => state.site.showLoginNeedModal,
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
    }),
  }),
  created() {
    this.getSite();
    this.getUserSubscriptions();
  },
  watch: {
    $route: ["getSite", "getUserSubscriptions"],
  },

  methods: {
    getSite() {
      const domain = this.$route.params.domain;
      this.$store.dispatch("site/getSite", domain);
    },
    getUserSubscriptions() {
      this.$store.dispatch("cabinet/subscriptions/getUserSubscriptions", 1);
    },
    hideOrShowLoginNeedModal() {
      this.$store.dispatch("site/setShowLoginNeedModal");
    },
  },
};
</script>