<template>
    <div class="w-14">
        <img
            :src="root + icon"
            class="max-w-full max-h-full rounded"
        />
    </div>
</template>

<script>
    export default {
        props: [
            'icon'
        ],
        data() {
            return {
                root: process.env.VUE_APP_BACKEND_URL,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>