<template>
  <div class="mb-5 p-3 bg-gray-50 dark:bg-gray-700 rounded">
    <button
      v-if="!version.response && isOwner() && !replyMode"
      @click="toggleReplyMode"
      class="
        float-right
        flex
        items-center
        bg-white
        text-webmoney
        px-2
        py-1
        rounded
        hover:bg-blue-400 hover:text-white
        shadow
        text-sm
      "
    >
      <corner-up-left-icon size="1x" class="mr-1"></corner-up-left-icon>
      <span> Ответить </span>
    </button>

    <div class="flex space-x-0 sm:space-x-4 mb-1 sm:flex-nowrap flex-wrap">
      <div
        class="text-gray-500 text-sm sm:w-auto w-full"
        v-if="version.created_at"
      >
        {{ $d(new Date(version.created_at), "short") }}
      </div>
      <div class="text-red-500 text-sm" v-if="version.status === 'rejected'">
        {{ $t("cabinet.feedback_version.status_rejected") }}
      </div>
    </div>

    <div class="text-yellow-400 mb-2" v-if="version.points">
      <star-icon
        size="1x"
        v-for="item in version.points"
        :key="item"
        class="inline-block fill-current"
      ></star-icon>

      <star-icon
        size="1x"
        v-for="item in 5 - version.points"
        :key="item + 5"
        class="inline-block"
      ></star-icon>
    </div>

    <div class="font-serif">
      <p class="mb-0">{{ version.text }}</p>
    </div>

    <div v-if="version.response || isOwner()">
      <div v-if="version.response" class="border-t dark:border-gray-700 mt-5 pt-5">
        <div class="">
          <p class="mb-3 text-gray-500 text-sm">
            {{ $t("shared.feedback.site_owner_reply") }}
          </p>
          <div class="flex">
            <div class="mr-3 w-16 flex-none">
              <Avatar :wmid="version.owner_wmid" />
            </div>
            <div class="">
              <p class="font-serif mb-2">{{ version.response }}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!version.response && isOwner() && replyMode"
        class="border-t dark:border-gray-700 mt-5 pt-5"
      >
        <p class="mb-3 text-gray-500 text-sm">
          {{ $t("shared.feedback.site_owner_reply") }}
        </p>

        <notifications
          classes="notification"
          class="form-notification"
          :group="'feedback-reply-' + version.id"
        />

        <div v-if="replySuccess">
          {{ $t("shared.feedback.reply_recieved") }}
        </div>

        <div v-if="!replySuccess" class="flex space-x-3">
          <div class="w-16 flex-none">
            <Avatar :wmid="user.wmid" />
          </div>

          <div class="flex-auto">
            <form @submit="sendResponse">
              <textarea
                :placeholder="$t('shared.feedback.site_owner_reply_text')"
                v-model="responseText"
                class="
                  mb-3
                  py-2
                  px-4
                  block
                  border
                  rounded
                  border-gray-300
                  dark:border-gray-700
                  focus:outline-none focus:ring-1 focus:border-webmoney
                  w-full
                  h-16
                "
              ></textarea>
              <button
                class="
                  inline-block
                  bg-webmoney
                  text-white
                  px-3
                  py-1
                  rounded
                  hover:bg-blue-400 hover:text-white
                  shadow
                  text-sm
                "
              >
                {{ $t("shared.save") }}
              </button>
              <button
                type="button"
                @click="toggleReplyMode"
                class="
                  inline-block
                  bg-white
                  text-webmoney
                  px-3
                  py-1
                  rounded
                  hover:bg-blue-400 hover:text-white
                  shadow
                  text-sm
                  ml-2
                "
              >
                {{ $t("shared.cancel") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StarIcon, CornerUpLeftIcon } from "vue-feather-icons";
import Avatar from "@/components/Avatar.vue";
import { mapState, mapGetters } from "vuex";
import ownerships from "@/api/ownerships";

export default {
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
      loading: false,
      responseText: "",
      replyMode: false,
      replySuccess: false,
    };
  },
  components: { StarIcon, Avatar, CornerUpLeftIcon },
  computed: mapState({
    user: (state) => state.auth.user,
    ...mapGetters({
      authStatus: "auth/authStatus",
      isLoggedIn: "auth/isLoggedIn",
    }),
  }),
  props: {
    version: {
      type: Object,
    },
    feedbackId: {
      type: Number,
    },
    siteId: {
      type: Number,
    },
  },
  methods: {
    toggleReplyMode() {
      this.replyMode = !this.replyMode;
    },
    sendResponse(e) {
      this.loading = true;
      this.$notify({
        group: "feedback-reply-" + this.version.id,
        clean: true,
      });
      ownerships
        .replyToFeedback(this.responseText, this.feedbackId, this.version.id)
        .then(() => {
          this.loading = false;
          this.$notify({
            group: "app",
            text: this.$t("shared.feedback.reply_successfully_saved"),
            duration: 5000,
            type: "success",
          });
          this.replySuccess = true;
        })
        .catch((error) => {
          this.loading = false;
          this.replySuccess = false;
          if (
            error.response.data.errors &&
            error.response.data.errors.length > 0
          ) {
            error.response.data.errors.forEach((element) =>
              this.$notify({
                group: "feedback-reply-" + this.version.id,
                text: element,
                duration: -1,
                type: "error",
              })
            );
          }
        });
      e.preventDefault();
    },
    isOwner() {
      return this.$store.getters["cabinet/ownerships/isOwner"](this.siteId);
    },
  },
};
</script>

<style>
</style>