<template>
  <div class="">
    <div class="border dark:border-gray-700 rounded p-5 mb-5">
      <div v-if="!isLoggedIn">
        {{ $t("shared.feedback.please_login_text") }}
        <a :href="root + 'login?back_url=' + $route.path" class="underline"
          >{{ $t("shared.feedback.please_login_link") }} </a
        >.
      </div>

      <div v-if="isLoggedIn && !createMode">
        {{ $t("shared.feedback.feedback_recieved") }}
      </div>

      <div v-if="isLoggedIn && createMode">
        <div class="">
          <notifications
            classes="notification"
            class="form-notification"
            :group="'feedback-create'"
          />

          <div class="flex space-x-3">
            <div class="w-16 flex-none">
              <Avatar :wmid="user.wmid" />
            </div>

            <div class="flex-auto">
              <div class="mb-1 flex items-center">
                <p class="text-lg font-bold">
                  {{ user.nickname || user.wmid }}
                </p>
                <span
                  class="
                    ml-2
                    text-white
                    bg-webmoney
                    text-xs
                    px-1.5
                    rounded
                    py-0.5
                  "
                  v-if="isLoggedIn"
                >
                  {{ $t("shared.feedback.your_feedback_about_site") }}
                </span>
              </div>
              <div class="mb-3">
                <div class="text-yellow-400 inline-block">
                  <star-icon
                    size="1x"
                    class="inline-block fill-current"
                    v-for="item in points"
                    :key="item"
                    @click="setPoints(item)"
                  ></star-icon>

                  <star-icon
                    size="1x"
                    class="inline-block"
                    v-for="item in 5 - points"
                    :key="points + item"
                    @click="setPoints(points + item)"
                  ></star-icon>
                </div>
                <span class="inline-block ml-2 italic">
                  - {{ $t("shared.feedback.choose_stars_for_rating") }}
                </span>
              </div>
              <div class="w-1/2">
                <form @submit="createFeedback">
                  <textarea
                    :placeholder="$t('shared.feedback.feedback_text')"
                    v-model="text"
                    class="
                      mb-3
                      py-2
                      px-4
                      block
                      border
                      rounded
                      border-gray-300
                      dark:border-gray-700
                      focus:outline-none
                      focus:ring-1
                      focus:border-webmoney
                      w-full
                      h-16
                    "
                  ></textarea>
                  <button
                    class="
                      inline-block
                      uppercase
                      bg-webmoney
                      text-white
                      px-5
                      py-2
                      rounded
                      hover:bg-blue-400
                      hover:text-white
                      shadow
                      text-sm
                    "
                  >
                    {{ $t("shared.save") }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import feedbacks from "@/api/feedbacks";
import { StarIcon } from "vue-feather-icons";
import Avatar from "@/components/Avatar.vue";

export default {
  data() {
    return {
      loading: false,
      points: 0,
      text: "",
      root: process.env.VUE_APP_BACKEND_URL,
      createMode: true,
    };
  },
  components: {
    StarIcon,
    Avatar,
  },
  props: {
    siteId: Number,
    domain: String,
  },
  computed: mapState({
    user: (state) => state.auth.user,
    ...mapGetters({
      authStatus: "auth/authStatus",
      isLoggedIn: "auth/isLoggedIn",
    }),
  }),
  methods: {
    setPoints(points) {
      this.points = points;
    },
    createFeedback(e) {
      this.loading = true;
      this.$notify({
        group: "feedback-create",
        clean: true,
      });
      feedbacks
        .createOrUpdateFeedback(this.text, this.points, this.siteId)
        .then(() => {
          this.loading = false;
          this.text = "";
          this.points = 0;
          this.createMode = false;
          this.$notify({
            group: "app",
            text: this.$t("shared.feedback.feedback_successfully_created"),
            duration: 5000,
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (
            error.response.data.errors &&
            error.response.data.errors.length > 0
          ) {
            error.response.data.errors.forEach((element) =>
              this.$notify({
                group: "feedback-create",
                text: element,
                duration: -1,
                type: "error",
              })
            );
          }
        });
      e.preventDefault();
    },
  },
};
</script>
