<template>
  <div v-if="site" class="mt-10">
    <h6
      class="
        font-bold
        uppercase
        text-lg text-gray-700
        dark:text-white
        mb-5
        px-4
      "
    >
      {{ $t("shared.feedbacks") }}
    </h6>

    <FeedbackCreate
      :siteId="site.id"
      :domain="site.domain"
      v-if="userFeedbackChecked && !userFeedback"
    />

    <div class="clear-both"></div>

    <Feedback
      v-if="userFeedback"
      v-bind:feedback="userFeedback"
      :showSite="false"
    />

    <div id="site-feedbacks">
      <Feedback
        v-for="feedback in site.feedbacks"
        :key="feedback.id"
        v-bind:feedback="feedback"
        :showSite="false"
      />

      <div
        class="text-center py-16"
        v-if="site.feedbacks && !site.feedbacks.length && !userFeedback"
      >
        <inbox-icon size="4x" class="block m-auto text-gray-300"></inbox-icon>
        <p class="text-gray-400">{{ $t("site.no_feedbacks") }}</p>
      </div>

      <div v-if="site.feedbacks_count > 3">
        <router-link
          :to="
            $i18nRoute({
              name: 'feedbacks',
              params: { domain: site.domain },
            })
          "
          class="
            inline-block
            uppercase
            bg-webmoney
            text-white
            px-5
            py-2
            rounded
            hover:bg-blue-400 hover:text-white
            shadow
            text-sm
          "
        >
          {{ $t("site.feedbacks_button") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Feedback from "@/components/Feedback.vue";
import FeedbackCreate from "@/components/FeedbackCreate.vue";
import { InboxIcon } from "vue-feather-icons";
import feedbacks from "@/api/feedbacks";
export default {
  props: ["site"],
  components: {
    Feedback,
    FeedbackCreate,
    InboxIcon,
  },
  data() {
    return {
      userFeedback: null,
      userFeedbackChecked: false,
    };
  },
  watch: {
    site: "getLastUserFeedback",
  },
  created() {
    this.getLastUserFeedback();
  },
  methods: {
    getLastUserFeedback() {
      this.userFeedback = null;
      this.userFeedbackChecked = false;
      feedbacks
        .getUserLastFeedbackForSite(this.site.id)
        .then((data) => {
          this.userFeedback = data;
          this.userFeedbackChecked = true;
        })
        .catch(() => {
          this.userFeedbackChecked = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
