<template>
    <div
        class="py-3 px-4 md:px-10 text-white"
        :class="{
            'bg-blue-500': status == 'webmoney',
            'bg-green-500': status == 'system',
            'bg-red-500': status == 'danger',
        }"
    >
        <p v-if="status == 'webmoney'">
            {{ $t("site.webmoney_site_status") }}
        </p>
        <p v-else-if="status == 'system'">
            {{ $t("site.system_site_status") }}
        </p>
        <p v-else-if="status == 'danger'">
            {{ $t("site.danger_site_status") }}
        </p>
    </div>
</template>

<script>
    export default {
        props: [
            'status'
        ]
    }
</script>

<style lang="scss" scoped>

</style>