<template>
  <div class="border dark:border-gray-700 rounded p-5 mb-5 pb-0 relative">
    <div
      class="text-right absolute right-5"
      v-click-outside="hideMenu"
      v-if="
        localFeedback.versions.length > 1 ||
        (isLoggedIn && user.wmid === localFeedback.user_wmid && !editMode)
      "
    >
      <div class="">
        <button class="text-gray-300 hover:text-gray-400" @click="toggleMenu">
          <more-vertical-icon size="1.5x"></more-vertical-icon>
        </button>
      </div>
      <div
        v-if="showMenu"
        class="
          flex
          whitespace-nowrap
          flex-col
          border-t border-l border-r
          dark:border-gray-700
          absolute
          right-0
          bg-white
          shadow
          text-sm text-gray-600
        "
      >
        <button
          class="
            text-left
            border-b
            px-3
            py-2
            hover:bg-gray-100
            flex
            items-center
          "
          @click="showAllVersions"
          v-if="localFeedback.versions.length > 1 && !allVersions"
        >
          <clock-icon size="1x" class="mr-1"></clock-icon>
          <span> Показать прошлые мнения </span>
        </button>
        <button
          class="
            text-left
            border-b
            px-3
            py-2
            hover:bg-gray-100
            flex
            items-center
          "
          @click="hideAllVersions"
          v-if="localFeedback.versions.length > 1 && allVersions"
        >
          <clock-icon size="1x" class="mr-1"></clock-icon>
          <span> Скрыть прошлые мнения </span>
        </button>
        <button
          class="
            text-left
            border-b
            px-3
            py-2
            hover:bg-gray-100
            flex
            items-center
          "
          v-if="
            isLoggedIn && user.wmid === localFeedback.user_wmid && !editMode
          "
          @click="toggleEditMode"
        >
          <edit-2-icon size="1x" class="mr-1"></edit-2-icon>
          <span>
            {{ $t("shared.feedback.change_feedback") }}
          </span>
        </button>
      </div>
    </div>

    <notifications
      classes="notification"
      class="form-notification"
      :group="'feedback-update'"
    />

    <div class="">
      <div class="mb-3" v-if="showSite">
        <router-link
          :to="
            $i18nRoute({
              name: 'site',
              params: { domain: localFeedback.site.domain },
            })
          "
          class=""
        >
          <div
            v-if="localFeedback.site.icon"
            class="inline-block w-10 align-middle mr-2"
          >
            <img
              :src="root + localFeedback.site.icon"
              class="max-w-full max-h-full border dark:border-gray-700 rounded"
            />
          </div>
          {{ localFeedback.site.domain }}
        </router-link>
      </div>

      <div class="">
        <div class="flex space-x-3">
          <div class="w-16 flex-none" v-if="showUser">
            <Avatar :wmid="localFeedback.user_wmid" />
          </div>

          <div class="flex-auto">
            <div class="mb-1 flex items-center" v-if="showUser">
              <p class="text-lg font-bold">
                {{ localFeedback.user_nickname || localFeedback.user_wmid }}
              </p>
              <span
                class="
                  ml-2
                  text-white
                  bg-webmoney
                  text-xs
                  px-1.5
                  rounded
                  py-0.5
                "
                v-if="isLoggedIn && user.wmid === localFeedback.user_wmid"
              >
                {{ $t("shared.feedback.your_feedback_about_site") }}
              </span>
            </div>

            <div v-if="!editMode">
              <div
                v-for="(version, index) in localFeedback.versions"
                :key="version.id"
              >
                <FeedbackVersion
                  :version="version"
                  :feedbackId="localFeedback.id"
                  :siteId="localFeedback.site_id"
                  v-if="index == 0 || allVersions"
                />
              </div>
            </div>

            <div class="mb-5" v-if="editMode">
              <div class="mb-3">
                <div class="text-yellow-400 inline-block">
                  <star-icon
                    size="1x"
                    v-for="item in points"
                    :key="item"
                    class="inline-block fill-current"
                    @click="setPoints(item)"
                  ></star-icon>

                  <star-icon
                    size="1x"
                    v-for="item in 5 - points"
                    :key="points + item"
                    class="inline-block"
                    @click="setPoints(points + item)"
                  ></star-icon>
                </div>
                <span class="inline-block ml-2 italic">
                  - {{ $t("shared.feedback.choose_stars_for_rating") }}
                </span>
              </div>

              <form @submit="updateFeedback">
                <textarea
                  :placeholder="$t('shared.feedback.feedback_text')"
                  v-model="text"
                  class="
                    mb-3
                    py-2
                    px-4
                    block
                    border
                    rounded
                    border-gray-300
                    dark:border-gray-700 dark:bg-gray-700
                    focus:outline-none focus:ring-1 focus:border-webmoney
                    w-full
                    h-16
                  "
                ></textarea>
                <button
                  class="
                    inline-block
                    bg-webmoney
                    text-white
                    px-3
                    py-1
                    rounded
                    hover:bg-blue-400 hover:text-white
                    shadow
                    text-sm
                  "
                >
                  {{ $t("shared.save") }}
                </button>

                <button
                  type="button"
                  v-if="editMode"
                  @click="toggleEditMode"
                  class="
                    inline-block
                    bg-white
                    text-webmoney
                    px-3
                    py-1
                    rounded
                    hover:bg-blue-400 hover:text-white
                    shadow
                    text-sm
                    ml-2
                  "
                >
                  {{ $t("shared.cancel") }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Avatar from "@/components/Avatar.vue";
import FeedbackVersion from "@/components/FeedbackVersion.vue";
import {
  StarIcon,
  Edit2Icon,
  MoreVerticalIcon,
  ClockIcon,
} from "vue-feather-icons";
import feedbacks from "@/api/feedbacks";

export default {
  name: "Feedback",
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
      loading: false,
      showMenu: false,
      allVersions: false,
      localFeedback: {},
      points: 0,
      text: "",
      editMode: false,
    };
  },
  components: {
    Avatar,
    MoreVerticalIcon,
    FeedbackVersion,
    StarIcon,
    Edit2Icon,
    ClockIcon,
  },
  props: {
    showUser: {
      type: Boolean,
      default: true,
    },
    showSite: {
      type: Boolean,
      default: true,
    },
    feedback: Object,
  },
  computed: mapState({
    user: (state) => state.auth.user,
    ...mapGetters({
      authStatus: "auth/authStatus",
      isLoggedIn: "auth/isLoggedIn",
    }),
  }),
  created() {
    this.localFeedback = this.feedback;
    this.text = this.feedback.versions[0].text;
    this.points = this.feedback.versions[0].points;
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    hideMenu() {
      this.showMenu = false;
    },
    showAllVersions() {
      this.allVersions = true;
      this.showMenu = false;
    },
    hideAllVersions() {
      this.allVersions = false;
      this.showMenu = false;
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
      this.showMenu = false;
    },
    updateFeedback(e) {
      this.loading = true;
      this.$notify({
        group: "feedback-update",
        clean: true,
      });
      feedbacks
        .createOrUpdateFeedback(this.text, this.points, this.feedback.site_id)
        .then((data) => {
          this.loading = false;
          this.$notify({
            group: "app",
            text: this.$t("shared.feedback.feedback_successfully_saved"),
            duration: 5000,
            type: "success",
          });
          this.localFeedback = data.data;
          this.editMode = false;
        })
        .catch((error) => {
          this.loading = false;
          if (
            error.response.data.errors &&
            error.response.data.errors.length > 0
          ) {
            error.response.data.errors.forEach((element) =>
              this.$notify({
                group: "feedback-update",
                text: element,
                duration: -1,
                type: "error",
              })
            );
          }
        });
      e.preventDefault();
    },

    setPoints(points) {
      this.points = points;
    },
  },
};
</script>
