<template>
  <div v-if="chartData && chartData.visits_count > 250" class="mt-10 mb-5">
    <h6 class="font-bold uppercase text-lg text-gray-700 dark:text-white mb-5">
      {{ $t("site.chart-title-1") }}
    </h6>

    <div class="w-2/3 mb-5">
      <div class="flex justify-center">
        <!-- <h6 class="">
                {{ $t("site.chart-title-2") }}
                </h6> -->
        <div class="flex items-center p-5 w-full">
          <h5 class="flex-1">
            {{ $t("site.visits") }}
          </h5>
          <h4 class="flex-1 text-right font-bold uppercase text-2xl">
            {{ chartData.visits_count }}
          </h4>
        </div>
        <div class="flex items-center p-5 w-full">
          <h6 class="flex-1">
            {{ $t("site.uniq_visits") }}
          </h6>
          <h6 class="flex-1 text-right font-bold uppercase text-2xl">
            {{ chartData.uniq_visits_count }}
          </h6>
        </div>
      </div>
    </div>
    <div class="w-full">
      <SiteChart :chartdata="chartData" :height="150" />
    </div>
  </div>
</template>

<script>
import SiteChart from "@/components/SiteChart.vue";
import sites from "@/api/sites";
export default {
  data() {
    return {
      chartData: null,
    };
  },
  components: {
    SiteChart,
  },
  created() {
    this.getSiteChartData();
  },
  watch: {
    $route: ["getSiteChartData"],
  },
  methods: {
    getSiteChartData() {
      const domain = this.$route.params.domain;
      sites.getSiteChartDataByDomain(domain).then((data) => {
        this.chartData = data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>