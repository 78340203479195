<template>
    <div>
        <div v-if="description.length > 300">
            <p v-if="descriptionCropped">
                {{ description | limitText }}
            </p>
            <p v-else>
                {{ description }}
            </p>
            <span
                v-if="descriptionCropped"
                @click="descriptionCropped = false"
                class="cursor-pointer text-gray-400 underline"
                >{{ $t("site.show_completely") }}</span
            >
        </div>

        <p v-else>
            {{ description }}
        </p>
    </div>
</template>

<script>
    export default {
        props: [
            'description'
        ],
        data() {
            return {
                descriptionCropped: true,
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>