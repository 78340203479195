<template>
    <div class="
            md:flex
            md:space-x-10
            bg-gray-700
            text-white
            px-4
            md:px-10
            py-5
            rounded-b
        "
    >
        <!-- <h6 class=""> -->
          <!-- {{ $t("site.indicators") }} -->
        <!-- </h6> -->
        <div class="flex-1 flex items-center mb-4 md:mb-0">
            <h6 v-html="$t('site.advisor_rank')" class="flex-1"></h6>
            <h4 class="flex-1 font-bold uppercase text-2xl text-right">
                {{ site.advisor_rank || "—" }}
            </h4>
        </div>

        <div class="flex-1 mb-4 md:mb-0 flex items-center">
            <h6 v-html="$t('site.webmoney_rank')" class="flex-1"></h6>
            <h4 class="flex-1 font-bold uppercase text-2xl text-right">
                {{ site.webmoney_rank || "—" }}
            </h4>
        </div>

        <div class="flex-1 mb-4 md:mb-0 flex items-center">
            <h6 v-html="$t('site.alexa_rank')" class="flex-1"></h6>
            <h4 class="flex-1 font-bold uppercase text-2xl text-right">
                {{ site.alexa_rank || "—" }}
            </h4>
        </div>

        <div class="flex-1 mb-4 md:mb-0 flex items-center">
            <h6 v-html="$t('site.users_rating')" class="flex-1"></h6>
            <h4
                class="
                    font-bold
                    uppercase
                    text-2xl text-right
                    flex
                    items-center
                "
            >
                <star-icon size="1x" class="inline-block mr-2"></star-icon>
                {{ site.average_feedbacks_point || "—" }}
            </h4>
        </div>
    </div>
</template>

<script>
    import {StarIcon} from 'vue-feather-icons'
    export default {
        props: [
            'site'
        ],
        components: {
            StarIcon
        }
    }
</script>

<style lang="scss" scoped>

</style>