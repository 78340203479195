<template>
  <a :href="eventsLink(wmid)" target="_blank">
    <img
      class="w-full max-h-full rounded"
      :src="avatarLink(wmid)"
      :alt="wmid"
    />
  </a>
</template>

<script>
export default {
  props: {
    wmid: {
      type: String,
      required: true,
    },
  },
  methods: {
    eventsLink(wmid) {
      return "https://events.wmtransfer.com/user/" + wmid;
    },
    avatarLink(wmid) {
      return (
        "https://events.web.money/open/avatar.aspx?t=url&s=normal&w=" + wmid
      );
    },
  },
};
</script>

<style>
</style>