<template>
    <div>
        <button
            v-if="isLoggedIn && isSubscribed()"
            @click="unsubscribe"
            class="
                inline-block
                uppercase
                text-white
                bg-red-500
                px-5
                py-2
                rounded
                hover:bg-red-400
                hover:text-white
                shadow
                text-sm
            "
        >
            <zap-icon
                size="1x"
                class="inline-block align-middle mr-2"
            ></zap-icon>
            <span class="inline-block align-middle">
                {{ $t("site.unsubscribe_button") }}
            </span>
        </button>

        <button
            v-if="isLoggedIn && !isSubscribed()"
            @click="subscribe"
            class="
                inline-block
                uppercase
                text-webmoney
                bg-white
                px-5
                py-2
                rounded
                hover:bg-blue-400
                hover:text-white
                shadow
                text-sm
            "
        >
            <heart-icon
                size="1x"
                class="inline-block align-middle mr-2"
            ></heart-icon>
            <span class="inline-block align-middle">
                {{ $t("site.subscribe_button") }}
            </span>
        </button>

        <button
            v-if="!isLoggedIn"
            @click="hideOrShowLoginNeedModal"
            class="
                inline-block
                uppercase
                text-webmoney
                bg-white
                px-5
                py-2
                rounded
                hover:bg-blue-400
                hover:text-white
                shadow
                text-sm
            "
        >
            <heart-icon
                size="1x"
                class="inline-block align-middle mr-2"
            ></heart-icon>
            <span class="inline-block align-middle">
                {{ $t("site.subscribe_button") }}
            </span>
        </button>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import {
        HeartIcon,
        ZapIcon,
    } from "vue-feather-icons";

    export default {
        components: {
            HeartIcon,
            ZapIcon,
        },
        computed: mapState({
            subscriptions: (state) => state.cabinet.subscriptions.list,
            ...mapGetters({
                isLoggedIn: "auth/isLoggedIn",
            }),
        }),
        methods: {
            isSubscribed() {
                const domain = this.$route.params.domain;
                let subscription = this.subscriptions.find(
                    (s) => s.site && s.site.domain === domain
                );
                return !!subscription;
            },
            subscribe() {
                const domain = this.$route.params.domain;
                this.$store.dispatch("cabinet/subscriptions/subscribe", domain);
            },
            unsubscribe() {
                const domain = this.$route.params.domain;
                this.$store.dispatch("cabinet/subscriptions/unsubscribe", domain);
            },
            hideOrShowLoginNeedModal() {
                this.$store.dispatch("site/setShowLoginNeedModal");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>