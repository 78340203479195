<template>
    <div class="flex items-center" v-if="subscriptions">
        <users-icon
            v-if="subscriptions.length === 0"
            size="1x"
            class="mr-1 inline-block"
        ></users-icon>

        <div v-else class="flex mr-2">
            <Avatar 
                v-for="(item, index) in subscriptions.slice(-3)"
                :key="index"
                :wmid="item.user_wmid"
                class="rounded-full w-8 h-8 overflow-hidden block"
                :class="[index == 0 ? 'ml-0' : '-ml-2']"
            />
        </div>

        {{
            $tc(
                "shared.subscribers_count",
                subscriptions.length
            )
        }}
    </div>
</template>

<script>
    import Avatar from '@/components/Avatar.vue'
    import {UsersIcon} from "vue-feather-icons";
    export default {
        components: {
            Avatar,
            UsersIcon,
        },
        props: [
            'subscriptions'
        ]
        
    }
</script>

<style lang="scss" scoped>

</style>