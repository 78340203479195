<template>
    <div
        class="relative"
    >
        <div
            v-if="explicit"
            class="
            absolute
            top-0
            left-0
            right-0
            bottom-0
            flex
            items-center
            z-10
            "
        >
            <div
                class="
                    text-white text-center text-xs
                    mx-auto
                    uppercase
                    tracking-wider
                    bg-gray-800
                    p-2
                "
            >
                {{ $t("shared.explicit_content") }}
            </div>
        </div>
        <img
            :src="root + screenshot"
            class="rounded"
            :class="{ 'filter blur-sm': explicit }"
        />
    </div>
</template>

<script>
    export default {
        props: [
            'explicit',
            'screenshot'
        ],
        data() {
            return {
                root: process.env.VUE_APP_BACKEND_URL,
            }
        }
        
    }
</script>

<style lang="scss" scoped>

</style>