<template>
    <div>
        <router-link
            v-if="isLoggedIn"
            :to="
                $i18nRoute({
                    name: 'compare-sites',
                    params: { sites: domain },
                })
            "
            class="
                inline-block
                uppercase
                text-webmoney
                bg-white
                px-5
                py-2
                rounded
                hover:bg-blue-400
                hover:text-white
                shadow
                text-sm
                ml-3
            "
        >
            <copy-icon
                size="1x"
                class="inline-block align-middle mr-2"
            ></copy-icon>
            <span class="inline-block align-middle">
                {{ $t("site.compare_button") }}
            </span>
        </router-link>

        <button
            v-if="!isLoggedIn"
            @click="hideOrShowLoginNeedModal"
            class="
                inline-block
                uppercase
                text-webmoney
                bg-white
                px-5
                py-2
                rounded
                hover:bg-blue-400
                hover:text-white
                shadow
                text-sm
                ml-3
            "
        >
            <copy-icon
                size="1x"
                class="inline-block align-middle mr-2"
            ></copy-icon>
            <span class="inline-block align-middle">
                {{ $t("site.compare_button") }}
            </span>
        </button>
    </div>
</template>

<script>
    import {CopyIcon} from "vue-feather-icons";
    import { mapGetters } from 'vuex';
    export default {
        components: {
            CopyIcon,
        },
        computed: mapGetters({
            isLoggedIn: "auth/isLoggedIn",
        }),
        props: [
            'domain'
        ],
        methods: {
            hideOrShowLoginNeedModal() {
                this.$store.dispatch("site/setShowLoginNeedModal");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>