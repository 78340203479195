<template>
  <div class="fixed inset-0 z-10">
    <div
      @click="$emit('close')"
      class="bg-gray-800 bg-opacity-75 inset-0 absolute"
    ></div>
    <div class="relative top-10">
      <div class="m-auto w-1/3 bg-white relative">
        <div class="p-10">
          <button
            @click="$emit('close')"
            type="button"
            class="absolute right-5 top-5"
          >
            <x-icon size="1x" class=""></x-icon>
          </button>

          <img
            class="mt-5 mb-10 m-auto"
            src="@/assets/images/modal-info-icon.png"
            alt="Information"
          />

          <h4
            class="
              font-bold
              text-center
              uppercase
              text-lg text-gray-700
              dark:text-white
              mb-5
            "
          >
            {{ $t("login.login_need") }}
          </h4>

          <p class="text-center">
            {{ $t("login.login_text") }}
          </p>

          <div class="text-center mt-5">
            <a
              :href="root + 'login?back_url=' + $route.path"
              class="
                inline-block
                bg-webmoney
                text-white
                px-8
                py-4
                rounded-full
                hover:bg-blue-400 hover:text-white
                shadow
              "
            >
              <log-in-icon
                size="1x"
                class="align-middle inline-block mr-2"
              ></log-in-icon>

              <span class="inline-block align-middle">
                {{ $t("login.login_link") }}</span
              >
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XIcon, LogInIcon } from "vue-feather-icons";
export default {
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
    };
  },
  components: {
    XIcon,
    LogInIcon,
  },
};
</script>

