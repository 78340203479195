<template>
    <a
        nohref="true"
        class="cursor-pointer flex items-center"
        @click="scrollToFeedbacks"
    >
        <message-circle-icon
            size="1x"
            class="mr-1 inline-block"
        ></message-circle-icon>
        {{ $tc("shared.feedbacks_count", feedbacksCount) }}
    </a>
</template>

<script>
    import {MessageCircleIcon} from "vue-feather-icons";
    export default {
        components: {
            MessageCircleIcon,
        },
        props: [
            'feedbacksCount'
        ],
        methods: {
            scrollToFeedbacks() {
                this.$nextTick(() => {
                    document.getElementById('site-feedbacks').scrollIntoView({
                        scrollBehavior: "smooth",
                    });
                });
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>