<template>
   <div>
        <div
            class="
                bg-gray-800
                p-4
                md:p-10
                text-white
                rounded-t
                relative
                overflow-hidden
            "
        >
            <div
                v-if="site.screenshot"
                class="
                    absolute
                    inset-0
                    z-0
                    overflow-hidden
                    bg-local bg-center bg-no-repeat bg-cover
                    filter
                    blur
                "
                    :style="{
                        backgroundImage: 'url(' + root + site.screenshot + ')',
                    }"
            >
                <div
                    class="
                        absolute
                        inset-0
                        bg-gray-800
                        z-10
                        opacity-70
                    "
                ></div>
            </div>

            <div class="flex items-start relative z-10">
                <div class="md:w-4/5">
                    <a
                        :href="`${site.scheme}://${site.domain}`"
                        rel="nofollow"
                        target="_blank"
                        class="flex items-center space-x-2 mb-4"
                    >
                        <SiteIcon 
                            v-if="site.icon" 
                            :icon="site.icon"
                        />
                        <h1 class="font-bold text-2xl">
                            {{ site.domain }}
                        </h1>
                        <div class="hidden md:inline-block">
                            <external-link-icon />
                        </div>
                    </a>

                    <Description
                        v-if="site.description"
                        :description="site.description" 
                        class="md:w-3/4"
                    /> 
                </div>
                <Screenshot
                    v-if="site.screenshot"
                    :explicit="site.explicit" 
                    :screenshot="site.screenshot" 
                    class="hidden md:inline-block w-1/5"
                />
            </div>

            <div class="md:flex items-center mt-8 relative z-10">
                <div class="md:w-1/2 mb-4 md:mb-0 text-center self-end md:text-left flex space-x-4">
                    <FeedbacksCount 
                        :feedbacksCount="site.feedbacks_count"
                    />
                    <Subscribers
                        :subscriptions="site.subscriptions"
                    />
                </div>
                
                <div class="md:w-1/2 flex justify-end space-x-3 text-center md:text-right">
                    <SubscribeButton />
                    <CompareButton 
                        v-if="site.domain"
                        :domain="site.domain"
                    />
                </div>
            </div>
        </div>

        <SiteStatus 
            v-if="site.status"
            :status="site.status"
        />
        <Ranks v-if="site" :site="site" />
    </div>
</template>

<script>
    import SiteStatus from './info/SiteStatus.vue'
    import SiteIcon from "./info/SiteIcon.vue";
    import Description from "./info/Description.vue";
    import Screenshot from "./info/Screenshot.vue";
    import Subscribers from "./info/Subscribers.vue";
    import FeedbacksCount from "./info/FeedbacksCount.vue";
    import SubscribeButton from "./info/SubscribeButton.vue";
    import CompareButton from "./info/CompareButton.vue";
    import { ExternalLinkIcon } from 'vue-feather-icons'
    import Ranks from './info/Ranks.vue';

    export default {
        props: [
            'site'
        ],
        components: {
            SiteStatus,
            SiteIcon,
            Description,
            Screenshot,
            Subscribers,
            FeedbacksCount,
            SubscribeButton,
            CompareButton,
            ExternalLinkIcon,
            Ranks,
        },
        data() {
            return {
                root: process.env.VUE_APP_BACKEND_URL,
            };
        },
        
    }
</script>

<style lang="scss" scoped>

</style>