<script>
import { Line } from "vue-chartjs";

let options = {
  legend: {
    display: true,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: false,
        },
      },
    ],
  },
};

export default {
  extends: Line,
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
  },

  computed: {
    chartDataInfo() {
      return {
        labels: this.chartdata.labels,
        datasets: [
          {
            label: this.$t("site.uniq_visits"),
            showLine: true,
            data: this.chartdata.uniq_data,
            backgroundColor: "#3498DB",
            borderColor: "#3498db",
            pointBorderColor: "#3498db",
            pointHoverBorderColor: "#3498db",
            pointRadius: 5,
            pointHoverRadius: 5,
            pointBorderWidth: 2,
            pointHoverBorderWidth: 2,
            borderWidth: 2,
            pointBackgroundColor: "#85c1e9",
            pointHoverBackgroundColor: "#3498db",
            steppedLine: "middle",
          },
          {
            label: this.$t("site.visits"),
            showLine: true,
            data: this.chartdata.data,
            backgroundColor: "#85c1e9",
            borderColor: "#3498db",
            pointHoverBorderColor: "#3498db",
            pointRadius: 5,
            pointHoverRadius: 5,
            pointBorderWidth: 2,
            pointHoverBorderWidth: 2,
            borderWidth: 2,
            pointBackgroundColor: "#85c1e9",
            pointHoverBackgroundColor: "#3498db",
            steppedLine: "middle",
          },
        ],
      };
    },
  },
  watch: {
    chartdata() {
      this.renderChart(this.chartDataInfo, options);
    },
  },
  mounted() {
    this.renderChart(this.chartDataInfo, options);
  },
};
</script>
